import React from "react";
import { useForm } from "react-hook-form";
import styles from "./styles.module.scss";
import {
  Button,
  Story,
  List,
  ListItem,
  InlineLoading,
  ReadMore,
} from "@wfp/ui";
import Blockquote from "@wfp/ui/lib/components/Blockquote";
import { notificationData } from "../../helpers/notificationData";
import { connect } from "react-redux";
import ConForm from "../ConForm";
import getInfo, { getNotificationDataFromType } from "../../helpers/getInfo";

function Content({
  fetching,
  donors,
  saved,
  profile,
  subscriptions,
  updateSubscriptions,
}) {
  const { control, handleSubmit } = useForm({
    defaultValues: subscriptions,
  });

  const onSubmit = (data) => {
    window.scrollTo(0, 0);
    updateSubscriptions({ data, id: profile.id });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Story className={`${styles.content} wfp--form-long`}>
        {subscriptions && saved && (
          <Blockquote
            icon={
              <img
                src={`${process.env.PUBLIC_URL}/comment-alt-smile-light.svg`}
                alt="envelop"
              />
            }
            withIcon
            kind="success"
            title="Your changes have been saved!"
          >
            <p>
              You will receive a notification any time a forecast or confirmed
              contribution to this topic is created or modified in WINGS.
            </p>

            <List kind="simple-inline" colon>
              {subscriptions && (
                <div>
                  {Object.entries(subscriptions).map((item, i) => (
                    <ListItem
                      key={i}
                      title={
                        getNotificationDataFromType(item[0]) &&
                        getNotificationDataFromType(item[0]).label
                      }
                    >
                      {item[0] === "Bptypecode" ? (
                        <>{item[0] ? "active" : "disabled"}</>
                      ) : (
                        <>
                          {item[1].map((element, i) => {
                            const value = getInfo(element, item[0], donors);
                            return (
                              <span key={i}>
                                {element.label}
                                {value && value.label}

                                {i + 1 !== item[1].length && <span>, </span>}
                              </span>
                            );
                          })}
                        </>
                      )}
                    </ListItem>
                  ))}
                </div>
              )}
            </List>
          </Blockquote>
        )}

        <h2>Notifications</h2>

        <Blockquote light>
          <ReadMore
            maxHeight={70}
            expandText={"Click here to read more"}
            fade={false}
          >
            <p>
              <strong>
                For support please contact{" "}
                <a href="mailto:pcsa.support@wfp.org">pcsa.support@wfp.org</a>
              </strong>
            </p>
            <p>
              Choose the subscription criteria based on the available selection
              fields and save. The notification works on the or/or logic,
              therefore if you select more than one criterion, you will receive
              notifications for all grants that meet any of the specified
              criteria.
            </p>

            <p>
              For instance, if you would like to receive notifications of
              contributions to your Country Office, only the field “Recipient
              Region/Country” should be activated. By activating other fields,
              you will also receive notifications activated under the other
              selection criteria.
            </p>

            <p>To receive ALL grants notification choose “All Grant Type”.</p>

            <p>To unsubscribe, remove all selections and save.</p>
          </ReadMore>
        </Blockquote>

        {donors && <ConForm selectData={notificationData} control={control} />}
      </Story>
      <div className={styles.submitWrapper}>
        <Button type="submit">Save changes</Button>
        {(fetching || saved) && (
          <InlineLoading
            success={saved}
            description={saved ? "Subscriptions saved" : "Loading..."}
          />
        )}
      </div>
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    fetching: state.reducer.fetching,
    donors: state.reducer.donors,
    saved: state.reducer.saved,
    profile: state.reducer.profile,
    error: state.reducer.error,
    subscriptions: state.reducer.subscriptions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadDonors: (kind) =>
      dispatch({ type: "API_CALL_DONORS_REQUEST", kind: kind, data: false }),
    getProfile: () => dispatch({ type: "API_CALL_PROFILE_REQUEST" }),
    getSubscriptions: (id) =>
      dispatch({ type: "API_CALL_GETSUBSCRIPTIONS_REQUEST", id }),
    updateSubscriptions: (data) =>
      dispatch({ type: "API_CALL_UPDATESUBSCRIPTIONS_REQUEST", data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
