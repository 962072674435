import { all, fork } from "redux-saga/effects";
import watcherGetAllDonorsSaga from "./getAllDonors";
import watcherGetTokenSaga from "./getToken";
import watcherGetProfileSaga from "./getProfile";
import getSubscriptions from "./getSubscriptions";
import updateSubscriptions from "./updateSubscriptions";

export default function* rootSaga() {
  yield all([
    fork(watcherGetAllDonorsSaga),
    fork(watcherGetTokenSaga),
    fork(watcherGetProfileSaga),
    fork(getSubscriptions),
    fork(updateSubscriptions)
  ]);
}
