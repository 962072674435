import React from "react";
import { Controller } from "react-hook-form";
import Checkbox from "../Checkbox";
import SelectInput from "../Select";

export default function ConForm({ donors, control, selectData }) {
  if (!selectData) return null;
  return (
    <div className="content__notifications">
      {selectData.map((item, i) => {
        return (
          <div key={i}>
            {item.value === "Bptypecode" ? (
              <Controller
                as={<Checkbox labelText={item.label} id={item.label} />}
                item={item}
                options={donors ? donors[item.api] : undefined}
                labelText={item.label}
                control={control}
                checkboxLabel={item.checkboxLabel}
                name={item.value}
                /*helperText="Select the contributions lorem ipsum et jomen"*/
              />
            ) : (
              <Controller
                as={
                  <SelectInput
                    item={item}
                    options={donors ? donors[item.api] : undefined}
                    labelText={item.label}
                    checkboxLabel={item.checkboxLabel}
                    /*helperText="Select the contributions lorem ipsum et jomen"*/
                  />
                }
                control={control}
                name={item.value}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}
