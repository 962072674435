import { call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import getEnvVars from "../environment";
const env = getEnvVars();

function* watcherSaga() {
  yield takeEvery("API_CALL_GETSUBSCRIPTIONS_REQUEST", workerSaga);
}

function fetch(userId) {
  const url = `${process.env.REACT_APP_LOCAL_API_URL}${userId}/subscriptions/`;
  return axios({
    method: "GET",
    ...env.axiosConfig,
    url: url,
  });
}

function* workerSaga(request) {
  try {
    const response = yield call(fetch, request.id);
    const result = response.data.criteria;
    yield put({ type: "API_CALL_GETSUBSCRIPTIONS_SUCCESS", result });
  } catch (error) {
    yield put({ type: "API_CALL_GETSUBSCRIPTIONS_FAILURE", error });
  }
}

export default watcherSaga;
