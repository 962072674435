import { runWithAdal } from "react-adal";
import { adalInstance } from "./adalConfig";

import * as Sentry from "@sentry/browser";
Sentry.init({
  dsn: "https://d793b174a9ea4fc999f7059ee6125b19@sentry.io/2261393",
  environment: process.env.NODE_ENV,
});

const DO_NOT_LOGIN = false;

runWithAdal(
  adalInstance,
  () => {
    require("./indexApp.js");
  },
  DO_NOT_LOGIN
);
