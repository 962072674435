import React from "react";
import Select, { components } from "react-select";

import { Input } from "@wfp/ui";
import lodash from "lodash";
import classNames from "classnames";
import { connect } from "react-redux";
import "./select.scss";

const MultiValueLabel = (props) => (
  <components.MultiValueLabel {...props}>
    <span className="option__additional">{props.data.additional}</span>
    {props.data.label}
  </components.MultiValueLabel>
);

const Option = (props) => {
  const optionClass = classNames({
    select_option__active: props.data.isSublevel === "main",
    select_option__summary: props.data.type === "summary",
  });

  return (
    <components.Option {...props} className={optionClass}>
      <span
        className="option__additional"
        style={{
          width: props.data.codeLength
            ? `${props.data.codeLength * 0.6}em`
            : undefined,
        }}
      >
        {props.data.additional}
      </span>
      {props.label}
    </components.Option>
  );
};

const formatGroupLabel = (data) => (
  <div className="group">
    <span>{data.label}</span>
    <span className="group-badge">{data.options.length}</span>
  </div>
);

function SelectInput(props) {
  const {
    className,
    control,
    donors,
    onChange,
    item,
    name,
    value,
    labelText,
    helperText,
  } = props;
  const { groupBy } = item;
  const invalid = control && lodash.get(control.errorsRef.current, name);
  const classes = classNames(`wfp--form-item commodity-select`, className, {
    [`wfp--text-input--invalid`]: invalid,
  });

  // List all Options and map them
  const options = donors
    .filter((e) => !item.excludes.includes(e[item.key]))
    .map((e, index) => {
      return {
        label: e[item.apiLabel],
        value: e,
        additional: e[item.key],
        codeLength: item.codeLength,
        key: index,
        groupBy: e.ParentCodeDesc,
      };
    });

  //Add all option to the top
  options.unshift({
    value: "__ALL__",
    isSublevel: "main",
    label: `All ${item.label}`,
    type: "all",
  });

  // Group list
  let groupedList = {};

  if (groupBy) {
    options.forEach((e) => {
      if (groupedList[e.groupBy] === undefined && e.groupBy !== undefined)
        groupedList[e.groupBy] = {
          label: e.groupBy === "" ? "uncategorized" : e.groupBy,
          options: [],
        };
    });

    // filter regions
    options.forEach((e) => {
      if (
        e.groupBy !== undefined &&
        (!item.onlyRb ||
          e.value.ParentCode.endsWith("RB") ||
          e.value.ParentCode.endsWith("PPR"))
      )
        groupedList[e.groupBy].options.push(e);
    });
  }

  groupedList = Object.values(groupedList);

  groupedList.unshift({
    value: item.label,
    isSublevel: "main",
    label: `All ${item.label}`,
    type: "all",
  });

  groupedList.map((e) => {
    if (e.options && e.options.length > 0)
      e.options.unshift({
        value: e.label,
        isSublevel: "main",
        label: `All ${e.label}`,
        type: "summary",
      });
    return e;
  });

  const find =
    value &&
    value.map((f) => {
      const index = donors.findIndex((e) => {
        if (e[item.key] === f) {
          return true;
        }
        return false;
      });

      const output =
        f === "__ALL__"
          ? {
              value: "__ALL__",
              label: `All ${item.label}`,
            }
          : {
              value: donors[index],
              additional: donors[index] ? donors[index][item.key] : "",
              codeLength: donors[index] ? donors[index][item.codeLength] : 4,
              label: donors[index] ? donors[index][item.apiLabel] : "not found",
            };

      return output;
    });

  if (donors.length <= 0) {
    return null;
  }

  return (
    <Input
      formItemClassName={classes}
      invalid={invalid}
      labelText={labelText}
      helperText={helperText}
      invalidText={invalid && invalid.message}
    >
      {() => (
        <Select
          isMulti
          isSearchable
          defaultValue={find}
          options={groupBy ? groupedList : options}
          components={{ MultiValueLabel, Option }}
          className="wfp--react-select-container"
          classNamePrefix="wfp--react-select"
          formatGroupLabel={groupBy ? formatGroupLabel : undefined}
          onChange={(selected) => {
            const filteredSelected = selected
              ? selected.reduce((accumulator, currentValue) => {
                  if (currentValue.type === "summary") {
                    console.log(
                      "isSummary",
                      currentValue.value,
                      groupedList.find((e) => e.label === currentValue.value)
                    );
                    const entries = groupedList.find(
                      (e) => e.label === currentValue.value
                    );

                    const options = entries.options.reduce(
                      (acc, f) =>
                        f.value[item.key] ? [...acc, f.value[item.key]] : acc,
                      []
                    );

                    console.log("options", options);
                    return entries ? [...accumulator, ...options] : accumulator;
                  }
                  const newCurrentValue = currentValue.value[item.key]
                    ? currentValue.value[item.key]
                    : "__ALL__";
                  return [...accumulator, newCurrentValue];
                }, []) /*selected.map((e) => {
                  return e.value[item.key] ? e.value[item.key] : "__ALL__";
                })*/
              : undefined;
            console.log(
              "filteredSelected",
              selected,
              filteredSelected,
              groupBy ? groupedList : undefined
            );
            onChange(filteredSelected);
          }}
        />
      )}
    </Input>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    donors: state.reducer.donors[ownProps.item.api]
      ? state.reducer.donors[ownProps.item.api]
      : [],
  };
};

export default connect(mapStateToProps)(SelectInput);
