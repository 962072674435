import React from "react";
import { Link, Footer } from "@wfp/ui";

export default function AppFooter({ pageSettings }) {
  return (
    <Footer {...pageSettings}>
      <div className="wfp--footer__info">
        <div className="wfp--footer__info__item">
          <p className="wfp--footer__label">Need help?</p>
          <ul className="wfp--footer__list">
            <li>
              <Link href="mailto:pcsa.support@wfp.org">Contact</Link>
            </li>
          </ul>
        </div>
        <div className="wfp--footer__info__item">
          <p className="wfp--footer__label">About</p>
          <ul className="wfp--footer__list">
            <li>
              <Link
                href="https://newgo.wfp.org/how-do-i/receive-grant-managment-notifications-on-contributions-and-forecasts"
                target="_blank"
              >
                About
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </Footer>
  );
}
