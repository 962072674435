import axios from "axios";
import {
  getLocalStorageTokenAzure,
  getLocalStorageTokenGateway,
} from "./api/api";

export default {
  setupInterceptors: (store) => {
    axios.interceptors.request.use(
      (config) => {
        const tokenAzure = getLocalStorageTokenAzure();
        const tokenGateway = getLocalStorageTokenGateway();
        if (config.url.includes(process.env.REACT_APP_API_URL)) {
          config.headers["Authorization"] = `Bearer ${tokenGateway}`;
        } else {
          config.headers["authorization"] = tokenAzure;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (
          error.response.status ===
          401 /* &&
          error.response.data._error === "User not logged in"*/
        ) {
          //localStorage.clear();
          //store.dispatch({ type: "LOGOUT", data: true });
        }
        return Promise.reject(error);
      }
    );
  },
};
