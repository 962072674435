export const getLocalStorageTokenAzure = () => {
  return window.localStorage.getItem("adal.idtoken");
};

export const getLocalStorageTokenGateway = () => {
  return window.localStorage.getItem("tokenGateway");
};

export const setLocalStorageTokenGateway = (token) => {
  window.localStorage.setItem("tokenGateway", token);
};

export const logout = () => {
  window.localStorage.clear();
  window.location.reload();
};
