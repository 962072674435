import React, { useEffect } from "react";
import "./global.scss";
import Content from "./sections/Content";
import { Wrapper } from "@wfp/ui";

import { connect } from "react-redux";
import { notificationData } from "./helpers/notificationData";
import Footer from "./sections/Footer";
import Loading from "@wfp/ui/lib/components/Loading";

import Navigation from "./sections/Navigation";
import Blockquote from "@wfp/ui/lib/components/Blockquote";

const pageSettings = { pageWidth: "lg" };

function App({
  donors,
  profile,
  error,
  getToken,
  getProfile,
  getSubscriptions,
  loadDonors,
  subscriptions,
  token,
}) {
  useEffect(() => {
    getToken();
    getProfile();
  }, [getToken, getProfile]);

  useEffect(() => {
    if (token) {
      notificationData.map((item) => {
        if (item.api) {
          loadDonors(item.api);
        }
        return null;
      });
    }
  }, [loadDonors, token]);

  useEffect(() => {
    if (profile) {
      getSubscriptions(profile.id);
    }
  }, [getSubscriptions, profile]);

  return (
    <div className="App">
      <Navigation profile={profile} />
      <Wrapper {...pageSettings} spacing="md">
        {subscriptions && Object.keys(donors).length >= 5 ? (
          <Content single={false} />
        ) : !error ? (
          <Loading />
        ) : (
          <Blockquote kind="warning" title="Loading failed">
            Please make sure that you are connected to the VPN.
          </Blockquote>
        )}
      </Wrapper>

      <Footer pageSettings={pageSettings} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    donors: state.reducer.donors,
    donorsLength: Object.keys(state.reducer.donors).length,
    token: state.reducer.token,
    profile: state.reducer.profile,
    error: state.reducer.error,
    subscriptions: state.reducer.subscriptions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadDonors: (kind) =>
      dispatch({ type: "API_CALL_DONORS_REQUEST", kind: kind, data: false }),
    getToken: () => dispatch({ type: "API_CALL_TOKEN_REQUEST" }),
    getProfile: () => dispatch({ type: "API_CALL_PROFILE_REQUEST" }),
    getSubscriptions: (id) =>
      dispatch({ type: "API_CALL_GETSUBSCRIPTIONS_REQUEST", id }),
    updateSubscriptions: (data) =>
      dispatch({ type: "API_CALL_UPDATESUBSCRIPTIONS_REQUEST", data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
