// reducer with initial state
const initialState = {
  fetching: false,
  donors: {},
  error: null
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "API_CALL_DONORS_REQUEST":
      return { ...state, fetching: true, error: null };
    case "API_CALL_DONORS_SUCCESS":
      state.donors[action.kind] = action.result;
      return { ...state, fetching: false, donors: state.donors };
    case "API_CALL_DONORS_FAILURE":
      return {
        ...state,
        fetching: false,
        donors: {},
        error: action.error,
        errorResponse: action.error.response
      };
    case "API_CALL_TOKEN_REQUEST":
      return { ...state, fetching: true, error: null };
    case "API_CALL_TOKEN_SUCCESS":
      return { ...state, fetching: false, token: action.data };
    case "API_CALL_TOKEN_FAILURE":
      return {
        ...state,
        fetching: false,
        token: null,
        error: action.error,
        errorResponse: action.error.response
      };

    case "API_CALL_PROFILE_REQUEST":
      return { ...state, fetching: true, error: null };
    case "API_CALL_PROFILE_SUCCESS":
      return { ...state, fetching: false, profile: action.result };
    case "API_CALL_PROFILE_FAILURE":
      return {
        ...state,
        fetching: false,
        donors: {},
        error: action.error,
        errorResponse: action.error.response
      };

    case "API_CALL_GETSUBSCRIPTIONS_REQUEST":
      return { ...state, fetching: true, error: null };
    case "API_CALL_GETSUBSCRIPTIONS_SUCCESS":
      return { ...state, fetching: false, subscriptions: action.result };
    case "API_CALL_GETSUBSCRIPTIONS_FAILURE":
      return {
        ...state,
        fetching: false,
        subscriptions: [],
        error: action.error,
        errorResponse: action.error.response
      };

    case "API_CALL_UPDATESUBSCRIPTIONS_REQUEST":
      return { ...state, fetching: true, error: null, saved: false };
    case "API_CALL_UPDATESUBSCRIPTIONS_SUCCESS":
      return {
        ...state,
        fetching: false,
        subscriptions: action.result,
        saved: true
      };
    case "API_CALL_UPDATESUBSCRIPTIONS_FAILURE":
      return {
        ...state,
        fetching: false,
        subscriptions: [],
        error: action.error,
        errorResponse: action.error.response
      };
    default:
      return state;
  }
}
