import { notificationData } from "./notificationData";

export function getNotificationDataFromType(type) {
  return notificationData.find((e) => e.value === type);
}

export default function getInfo(key, type, donors) {
  const item = getNotificationDataFromType(type);
  const search = donors[item.api];
  if (!search) return null;

  const index = search.findIndex((e) => {
    return e[item.key] === key ? true : false;
  });

  const output =
    key === "__ALL__"
      ? {
          value: "__ALL__",
          label: `all ${item.label}`,
        }
      : {
          value: search[index],
          label: search[index] ? search[index][item.apiLabel] : "not found",
        };

  return output;
}
