import React from "react";
import { Checkbox, Input } from "@wfp/ui";
import lodash from "lodash";
import classNames from "classnames";
import "./checkbox.scss";
import { connect } from "react-redux";

function CheckboxInput(props) {
  const {
    className,
    control,
    checkboxLabel,
    onChange,
    name,
    labelText,
    helperText,
    value,
  } = props;
  const invalid = control && lodash.get(control.errorsRef.current, name);
  const classes = classNames(`wfp--form-item commodity-select`, className, {
    [`wfp--text-input--invalid`]: invalid,
  });

  return (
    <Input
      formItemClassName={classes}
      invalid={invalid}
      labelText={labelText}
      helperText={helperText}
      invalidText={invalid && invalid.message}
    >
      {(props) => (
        <Checkbox
          checked={value === "PRVT" || (value && value[0] === "PRVT")}
          labelText={checkboxLabel}
          indeterminate={false}
          disabled={false}
          name={name}
          id="12"
          hideLabel={false}
          onChange={(selected) => {
            onChange(selected ? "PRVT" : false);
          }}
        />
      )}
    </Input>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    donors: state.reducer.donors[ownProps.item.api]
      ? state.reducer.donors[ownProps.item.api]
      : [],
  };
};

export default connect(mapStateToProps)(CheckboxInput);
