export const notificationData = [
  {
    value: "Granttypecode",
    label: "Grant Type",
    api: "Z_MR_CONTRIBUTION_TYPE_SRV",
    key: "GrantType",
    apiLabel: "Description",
    codeLength: 4,
    excludes: ["AV", "MI", "ZZ", "ZG", "ZC"],
  },
  {
    value: "Recipientcode",
    label: "Recipient Region/Country",
    api: "Z_MR_FUND_CENTER_GROUP_SRV",
    key: "Fistl",
    apiLabel: "Beschr",
    groupBy: "ParentCodeDesc",
    onlyRb: true,
    codeLength: 6,
    excludes: [],
  },
  {
    value: "Sponsoredprogramcode",
    label: "Trust Fund",
    key: "SponsoredProg",
    api: "Z_MR_SPONSORED_PROG_SRV",
    apiLabel: "Description",
    codeLength: 9,
    excludes: [],
  },
  {
    value: "Functionalareacode",
    label: "Functional Area",
    key: "FuncArea",
    api: "Z_MR_FUNC_AREA_SRV",
    apiLabel: "Fkbtx",
    groupBy: "ParentCodeDesc",
    codeLength: 5,
    excludes: [],
  },
  {
    value: "Donorrollupcode",
    label: "Donor Rollup",
    key: "CountryId",
    api: "Z_MR_DONOR_COUNTRY_SRV",
    apiLabel: "Countrydesc",
    codeLength: 5,
    excludes: [],
  },
  {
    value: "Bptypecode",
    label: "Private Sector",
    key: "Bptypecode",
    checkboxLabel: "All private sector notifications",
    apiLabel: "Beschr",
    codeLength: 3,
    excludes: [],
  },
];
