import { call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import getEnvVars from "../environment";
const env = getEnvVars();

function* watcherGetAllDonorsSaga() {
  yield takeEvery("API_CALL_DONORS_REQUEST", workerGetAllDonorsSaga);
}

function fetchDonors(notificationType) {
  const url = `${
    process.env.REACT_APP_API_URL
  }wings/${notificationType}/ReturnSet${
    process.env.REACT_APP_API_ADD ? process.env.REACT_APP_API_ADD : ""
  }`;

  return axios({
    method: "GET",
    ...env.axiosConfig,
    url: url,
  });
}

function* workerGetAllDonorsSaga(request) {
  try {
    const response = yield call(fetchDonors, request.kind);
    const result = response.data.d.results;
    yield put({ type: "API_CALL_DONORS_SUCCESS", result, kind: request.kind });
  } catch (error) {
    yield put({ type: "API_CALL_DONORS_FAILURE", error });
  }
}

export default watcherGetAllDonorsSaga;
