import { call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import getEnvVars from "../environment";
import { setLocalStorageTokenGateway } from "../api/api";
const env = getEnvVars();

function* watcherGetAllDonorsSaga() {
  yield takeEvery("API_CALL_TOKEN_REQUEST", workerGetAllDonorsSaga);
}

function fetchDonors(notificationType) {
  const url = `${process.env.REACT_APP_LOCAL_API_URL}token/`;

  return axios({
    method: "GET",
    ...env.axiosConfig,
    url: url,
  });
}

function* workerGetAllDonorsSaga(request) {
  try {
    const response = yield call(fetchDonors, request.kind);
    const result = response.data;

    setLocalStorageTokenGateway(response.data.access_token);
    yield put({
      type: "API_CALL_TOKEN_SUCCESS",
      result,
      data: response.data.access_token,
    });
  } catch (error) {
    yield put({ type: "API_CALL_TOKEN_FAILURE", error });
  }
}

export default watcherGetAllDonorsSaga;
