import { call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import getEnvVars from "../environment";
const env = getEnvVars();

function* watcherSaga() {
  yield takeEvery("API_CALL_UPDATESUBSCRIPTIONS_REQUEST", workerSaga);
}

function fetch(request) {
  const url = `${process.env.REACT_APP_LOCAL_API_URL}${request.id}/subscriptions/`;
  const postData = JSON.parse(JSON.stringify(request.data));
  return axios({
    method: "PUT",
    ...env.axiosConfig,
    url: url,
    data: postData
  });
}

function* workerSaga(request) {
  try {
    const response = yield call(fetch, request.data);
    const result = response.data.criteria;
    yield put({ type: "API_CALL_UPDATESUBSCRIPTIONS_SUCCESS", result });
  } catch (error) {
    yield put({ type: "API_CALL_UPDATESUBSCRIPTIONS_FAILURE", error });
  }
}

export default watcherSaga;
