import React from "react";
import {
  Button,
  MainNavigation,
  MainNavigationItem,
  SubNavigation,
  SubNavigationHeader,
  SubNavigationLink,
  SubNavigationTitle,
  User,
} from "@wfp/ui";

import { logout } from "../../api/api";

const pageSettings = { pageWidth: "lg" };

export default function Navigation({ profile }) {
  return (
    <MainNavigation
      logo={
        <span>
          <span className="show--mobile">GM Notifications</span>
          <span className="show--desktop">Grants Management Notifications</span>
        </span>
      }
      {...pageSettings}
    >
      {profile && (
        <MainNavigationItem
          className="wfp--main-navigation__user"
          subNavigation={
            <SubNavigation>
              <SubNavigationHeader>
                <SubNavigationTitle>
                  Welcome {profile.first_name} {profile.last_name}!
                </SubNavigationTitle>
                <SubNavigationLink>
                  <Button
                    onClick={logout}
                    icon={{
                      styles: "",
                      viewBox: "0 0 512 512",
                      width: "512",
                      height: "512",
                      svgData: {
                        circles: "",
                        ellipses: "",
                        paths: [
                          {
                            fill: "currentColor",
                            d:
                              "M180 448H96c-53 0-96-43-96-96V160c0-53 43-96 96-96h84c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H96c-17.7 0-32 14.3-32 32v192c0 17.7 14.3 32 32 32h84c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm117.9-303.1l77.6 71.1H184c-13.3 0-24 10.7-24 24v32c0 13.3 10.7 24 24 24h191.5l-77.6 71.1c-10.1 9.2-10.4 25-.8 34.7l21.9 21.9c9.3 9.3 24.5 9.4 33.9.1l152-150.8c9.5-9.4 9.5-24.7 0-34.1L353 88.3c-9.4-9.3-24.5-9.3-33.9.1l-21.9 21.9c-9.7 9.6-9.3 25.4.7 34.6z",
                          },
                        ],
                        polygons: "",
                        polylines: "",
                        rects: "",
                      },
                    }}
                    kind="ghost"
                    small
                  >
                    Logout
                  </Button>
                </SubNavigationLink>
              </SubNavigationHeader>
              {/*<SubNavigationContent>
                  Additional content can be placed here.
                  <br />
                  Demo for internal close action:{" "}
                </SubNavigationContent>*/}
            </SubNavigation>
          }
        >
          <User
            ellipsis
            name={`${profile.first_name} ${profile.last_name}`}
            title={profile.username}
          />
        </MainNavigationItem>
      )}
    </MainNavigation>
  );
}
