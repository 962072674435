import {
  AuthenticationContext,
  adalGetToken,
  adalFetch,
  withAdalLogin
} from "react-adal";

export const adalConfig = {
  tenant: process.env.REACT_APP_TENANT,
  clientId: process.env.REACT_APP_CLIENTID,
  redirectUri: process.env.REACT_APP_URL,
  endpoints: {
    api: ""
  },
  cacheLocation: "localStorage",
  postLogoutRedirectUri: process.env.REACT_APP_URL
};

export const adalInstance = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(adalInstance, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(
  adalInstance,
  adalConfig.endpoints.api
);

export const getToken = async () => {
  return await adalGetToken(adalInstance, adalConfig.endpoints.api);
};

export const getTokenSync = () => {
  return adalGetToken(adalInstance, adalConfig.endpoints.api);
};

export const buildHeader = () => {
  return getToken()
    .then(token => {
      return token;
    })
    .catch(errors => {});
};
